<template>
	<div id="datos-vp">
		<v-card>
			<v-card-text style="background: #eee">
				<v-row class="mt-2">
					<v-col cols="12" md="12" sm="12">
						<base-material-card color="primary" class="px-5 py-3">
							<template v-slot:heading>
								<div class="text-h5 font-weight-light my-2">
									Datos del Trámite
								</div>
							</template>
							<v-card-text>
								<v-row class="text-left pa-2">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="4"> Número de Certificado: </v-col>
											<v-col
												cols="12"
												sm="8"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ formData.tramite.numero_certificado }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0">
										<v-row>
											<v-col cols="12" sm="3"> Tipo Certificado:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ tipoCertificado }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-2">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="4"> Tipo de Tramite: </v-col>
											<v-col
												cols="12"
												sm="8"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ tipoTramite }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0">
										<v-row>
											<v-col cols="12" sm="3"> Chasis:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ formData.tramite.chasis }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>

								<v-row class="text-left pa-2">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="4"> Tipo de Vehículo: </v-col>
											<v-col
												cols="12"
												sm="8"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ tipoVehiculo }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0">
										<v-row>
											<v-col cols="12" sm="3"> Certifica Firma:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													formData.tramite.certifica_firma == "S" ? "SI" : "NO"
												}}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-2">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="4"> Sociedad de Hecho: </v-col>
											<v-col
												cols="12"
												sm="8"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													formData.tramite.sociedad_hecho == "S" ? "SI" : "NO"
												}}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0">
										<v-row>
											<v-col cols="12" sm="3"> Formulario 12:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ formData.tramite.formulario12 }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-2">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="4"> Cantidad de Titulares: </v-col>
											<v-col
												cols="12"
												sm="8"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ formData.tramite.titulares }}</v-col
											>
										</v-row>
									</v-col>
								</v-row>
							</v-card-text>
						</base-material-card>
					</v-col>
				</v-row>

				<v-row class="mt-2">
					<v-col cols="12" md="12" sm="12">
						<base-material-card color="primary" class="px-5 py-3">
							<template v-slot:heading>
								<div class="text-h5 font-weight-light my-2">
									Datos Titulares ({{ formData.tramite.titulares }})
								</div>
							</template>
							<v-card-text>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="12" sm="12" class="pa-0 pr-2">
										<v-expansion-panels v-model="panel" multiple>
											<v-expansion-panel
												v-for="item in datosTitulares"
												:key="item.id"
											>
												<v-expansion-panel-header
													class="primary-2 text-white text-h7 text--accent-2 text-left"
													>{{
														item.lastName + ", " + item.firstName
													}}</v-expansion-panel-header
												>
												<v-expansion-panel-content>
													<v-card outlined>
														<v-card-text>
															<v-row class="text-left pa-2">
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Formulario 01
																		</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.formulario_01
																					? item.formulario_01
																					: ""
																			}}</v-col
																		>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Tipo de Persona:</v-col
																		>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.personType
																					? item.personType.name
																					: ""
																			}}
																		</v-col>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-2"
																>
																	<v-row>
																		<v-col cols="12" sm="5"> CUIT/CUIL:</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{ item.cuit ? item.cuit : "" }}
																		</v-col>
																	</v-row>
																</v-col>
															</v-row>
															<v-row class="text-left pa-2">
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Tipo de Doc.
																		</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.tipo_documento
																					? item.tipo_documento.name
																					: ""
																			}}</v-col
																		>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5"> Documento:</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{ item.documento ? item.documento : "" }}
																		</v-col>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-2"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Porc. de Tit.:</v-col
																		>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.porcentaje ? item.porcentaje : ""
																			}}
																		</v-col>
																	</v-row>
																</v-col>
															</v-row>
															<v-row class="text-left pa-2">
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Fecha de Nac.
																		</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.birthDate ? item.birthDate : ""
																			}}</v-col
																		>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Lugar de Nac.:</v-col
																		>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.birthPlace ? item.birthPlace : ""
																			}}
																		</v-col>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-2"
																>
																	<v-row>
																		<v-col cols="12" sm="5"> Sexo:</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.genderId ? item.genderId.name : ""
																			}}
																		</v-col>
																	</v-row>
																</v-col>
															</v-row>
															<v-row class="text-left pa-2">
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Estado Civil:</v-col
																		>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.maritalStatusId
																					? item.maritalStatusId.name
																					: ""
																			}}
																		</v-col>
																	</v-row>
																</v-col>

																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Autoridad expidio:
																		</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.pais_dni ? item.pais_dni.id : ""
																			}}</v-col
																		>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-2"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Nacionalidad:</v-col
																		>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.nacionalidad
																					? item.nacionalidad.id
																					: ""
																			}}
																		</v-col>
																	</v-row>
																</v-col>
															</v-row>

															<v-row class="text-left pa-2">
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5"> Ocupación </v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.activity ? item.activity : ""
																			}}</v-col
																		>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5"> Email:</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{ item.mail ? item.mail : "" }}
																		</v-col>
																	</v-row>
																</v-col>
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-2"
																>
																	<v-row>
																		<v-col cols="12" sm="5"> Telefono:</v-col>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{ item.telefono ? item.telefono : "" }}
																		</v-col>
																	</v-row>
																</v-col>
															</v-row>
															<v-row class="text-left pa-2">
																<v-col
																	cols="12"
																	md="4"
																	sm="4"
																	class="pa-0 pr-4"
																>
																	<v-row>
																		<v-col cols="12" sm="5">
																			Posee Apoderado:</v-col
																		>
																		<v-col
																			cols="12"
																			sm="7"
																			class="text-left font-weight-bold font-italic border-bottom-cell"
																		>
																			{{
																				item.apoderado ? item.apoderado : "NO"
																			}}
																		</v-col>
																	</v-row>
																</v-col>
															</v-row>

															<v-row class="text-left pa-2">
																<v-col
																	cols="12"
																	md="6"
																	sm="6"
																	class="pa-0 pr-2"
																>
																	<v-expansion-panels>
																		<v-expansion-panel>
																			<v-expansion-panel-header
																				class="primary-2 text-white text-h7 text--accent-2 text-left"
																				>Domicilio
																				Legal</v-expansion-panel-header
																			>
																			<v-expansion-panel-content>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row class="mt-3">
																							<v-col cols="12" sm="3">
																								Calle:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal
																										? item.domicilioLegal.calle
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Numero:</v-col
																							>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal
																										? item.domicilioLegal.numero
																										: ""
																								}}
																							</v-col>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Piso:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal
																										? item.domicilioLegal.piso
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Depto:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal
																										? item.domicilioLegal.depto
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Provincia:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal.provincia
																										? item.domicilioLegal
																												.provincia.name
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Localidad:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal
																										? item.domicilioLegal
																												.localidad
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Barrio/Paraje:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal
																										? item.domicilioLegal.barrio
																												.name
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Código Postal
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal
																										? item.domicilioLegal.cp
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="5">
																								Partido / Municipio / Depto
																							</v-col>
																							<v-col
																								cols="12"
																								sm="7"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioLegal
																										? item.domicilioLegal
																												.partido
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																			</v-expansion-panel-content>
																		</v-expansion-panel>
																	</v-expansion-panels>
																</v-col>
																<v-col
																	cols="12"
																	md="6"
																	sm="6"
																	class="pa-0 pr-2"
																>
																	<v-expansion-panels>
																		<v-expansion-panel>
																			<v-expansion-panel-header
																				class="primary-2 text-white text-h7 text--accent-2 text-left"
																				>Domicilio
																				Real</v-expansion-panel-header
																			>
																			<v-expansion-panel-content>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row class="mt-3">
																							<v-col cols="12" sm="3">
																								Calle:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal
																										? item.domicilioReal.calle
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Numero:</v-col
																							>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal
																										? item.domicilioReal.numero
																										: ""
																								}}
																							</v-col>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Piso:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal
																										? item.domicilioReal.piso
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Depto:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal
																										? item.domicilioReal.depto
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Provincia:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal.provincia
																										? item.domicilioReal
																												.provincia.name
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Localidad:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal
																										? item.domicilioReal
																												.localidad
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Barrio/Paraje:
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal
																										? item.domicilioReal.barrio
																												.name
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="3">
																								Código Postal
																							</v-col>
																							<v-col
																								cols="12"
																								sm="9"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal
																										? item.domicilioReal.cp
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																				<v-row class="text-left pa-2">
																					<v-col
																						cols="12"
																						md="12"
																						sm="12"
																						class="pa-0 pr-2"
																					>
																						<v-row>
																							<v-col cols="12" sm="5">
																								Partido / Municipio / Depto
																							</v-col>
																							<v-col
																								cols="12"
																								sm="7"
																								class="text-left font-weight-bold font-italic border-bottom-cell"
																							>
																								{{
																									item.domicilioReal
																										? item.domicilioReal.partido
																										: ""
																								}}</v-col
																							>
																						</v-row>
																					</v-col>
																				</v-row>
																			</v-expansion-panel-content>
																		</v-expansion-panel>
																	</v-expansion-panels>
																</v-col>
															</v-row>
														</v-card-text>
													</v-card>
												</v-expansion-panel-content>
												<br />
											</v-expansion-panel>
										</v-expansion-panels>
									</v-col>
								</v-row>
							</v-card-text>
						</base-material-card>
					</v-col>
				</v-row>

				<v-row class="mt-2">
					<v-col cols="12" md="12" sm="12">
						<base-material-card color="primary" class="px-5 py-3">
							<template v-slot:heading>
								<div class="text-h5 font-weight-light my-2">
									Datos del Vehículo
								</div>
							</template>
							<v-card-text>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Año del Modelo: </v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.aniomodelo }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Tipo Certificado:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.tipo_certificado }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> N° Certificado: </v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.numero_certificado }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Valuación:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.valuacion }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Motor: </v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.motor }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Chasis:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.chasis }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> MTM/FMM: </v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.mtm }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Marca:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.marca }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Modelo: </v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.modelo }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Tipo:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.tipo }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="6" sm="12" class="pa-0 pr-4 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Marca del Motor: </v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.marca_motor }}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" sm="6" class="pa-0 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Marca del Chasis:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosVehiculo.marca_del_chasis }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="6" sm="6" class="pa-0 pr-4 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Comerciante: </v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosVehiculo.comerciante
														? datosVehiculo.comerciante.nombre
														: ""
												}}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" md="6" sm="6" class="pa-0 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Tipo de Uso:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosVehiculo.tipo_de_uso_id
														? datosVehiculo.tipo_de_uso_id.nombre
														: ""
												}}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" sm="6" class="pa-0 pr-4 pt-2">
										<v-row>
											<v-col cols="12" sm="3"> Placa de Traslado:</v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosVehiculo.placa_de_traslado
														? datosVehiculo.placa_de_traslado.nombre
														: ""
												}}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-card-text>
						</base-material-card>
					</v-col>
				</v-row>

				<v-row class="mt-2">
					<v-col cols="12" md="12" sm="12">
						<base-material-card color="primary" class="px-5 py-3">
							<template v-slot:heading>
								<div class="text-h5 font-weight-light my-2">
									Datos de la Adquisición
								</div>
							</template>
							<v-card-text>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="4" sm="4" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Fecha Adquisición: </v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.fecha_adquisicion
														? datosAdquisicion.fecha_adquisicion
														: ""
												}}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" md="4" sm="4" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Tipo de Adquisición:</v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.tipo_adquisicion_id
														? datosAdquisicion.tipo_adquisicion_id.nombre
														: ""
												}}
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="12" md="4" sm="4" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Modo Adquisición: </v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.modo_adquisicion_id
														? datosAdquisicion.modo_adquisicion_id.nombre
														: ""
												}}</v-col
											>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="4" sm="4" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Moneda: </v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.moneda_id
														? datosAdquisicion.moneda_id.nombre
														: ""
												}}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" md="4" sm="4" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Importe:</v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.importe
														? datosAdquisicion.importe
														: ""
												}}
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="12" md="4" sm="4" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Punto de Venta:</v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.punto_venta
														? datosAdquisicion.punto_venta
														: ""
												}}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="4" sm="4" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Tipo de Factura: </v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.tipo_factura_id
														? datosAdquisicion.tipo_factura_id.nombre
														: ""
												}}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" md="4" sm="4" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Factura:</v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.factura
														? datosAdquisicion.factura
														: ""
												}}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="66" sm="6" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="6">
												Segundo elemento probatorio:
											</v-col>
											<v-col
												cols="12"
												sm="6"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.probatorio
														? datosAdquisicion.probatorio.nombre
														: ""
												}}</v-col
											>
										</v-row>
									</v-col>
									<v-col cols="12" md="6" sm="6" class="pa-0 pt-2 pr-4">
										<v-row>
											<v-col cols="12" sm="6">
												Razón Social Elemento Probatorio:</v-col
											>
											<v-col
												cols="12"
												sm="6"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosAdquisicion.razon_social_probatorio
														? datosAdquisicion.razon_social_probatorio
														: ""
												}}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-card-text>
						</base-material-card>
					</v-col>
				</v-row>

				<v-row class="mt-1">
					<v-col cols="12" md="12" sm="12">
						<base-material-card color="primary" class="px-5 py-1">
							<template v-slot:heading>
								<div class="text-h5 font-weight-light my-2">
									Datos del Seguro
								</div>
							</template>
							<v-card-text>
								<v-row class="text-left pa-0">
									<v-col cols="12" md="6" sm="6" class="pa-0 pr-2">
										<v-card outlined>
											<v-card-title
												class="primary text-white text-h7 text--accent-2 text-left"
											>
												<v-row wrap>
													<v-col cols="12" md="12" sm="12">
														Datos del Seguro
													</v-col>
												</v-row>
											</v-card-title>
											<v-card-text>
												<v-row class="text-left pa-0 pt-6">
													<v-col cols="12" md="12" sm="12" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" sm="4">
																Solicita Cotización:
															</v-col>
															<v-col
																cols="12"
																sm="8"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.solicitaCotizacion
																		? datosSeguro.solicitaCotizacion
																		: ""
																}}</v-col
															>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="text-left pa-0 pt-4">
													<v-col cols="12" md="12" sm="12" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" sm="4"> Compañía:</v-col>
															<v-col
																cols="12"
																sm="8"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.aseguradora_id ? aseguradora : ""
																}}
															</v-col>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="text-left pa-0 pt-4">
													<v-col cols="12" md="12" sm="12" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" sm="4">
																Tipo de Cobertura:
															</v-col>
															<v-col
																cols="12"
																sm="8"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.tipo_cobertura_id ? cobertura : ""
																}}</v-col
															>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="text-left pb-4 pa-0 pt-4">
													<v-col cols="12" md="8" sm="12" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" sm="6"> Póliza:</v-col>
															<v-col
																cols="12"
																sm="6"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.nro_poliza
																		? datosSeguro.nro_poliza
																		: ""
																}}</v-col
															>
														</v-row>
													</v-col>
													<v-col cols="12" md="4" sm="6" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" sm="6"> Importe:</v-col>
															<v-col
																cols="12"
																sm="6"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.importe ? datosSeguro.importe : ""
																}}
															</v-col>
														</v-row>
													</v-col>
												</v-row>
											</v-card-text>
										</v-card>
									</v-col>
									<v-col cols="12" md="6" sm="6" class="pa-0 pr-2">
										<v-card outlined>
											<v-card-title
												class="primary text-white text-h7 text--accent-2 text-left"
											>
												<v-row wrap>
													<v-col cols="12" md="12" sm="12">
														Guarda Habitual
													</v-col>
												</v-row>
											</v-card-title>
											<v-card-text>
												<v-row class="text-left pa-2 pt-6">
													<v-col cols="12" md="6" sm="6" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" sm="5"> Calle:</v-col>
															<v-col
																cols="12"
																sm="7"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.calle
																		: ""
																}}</v-col
															>
														</v-row>
													</v-col>
													<v-col cols="12" md="6" sm="6" class="pa-0 pr-2">
														<v-row>
															<v-col cols="12" sm="5"> Número:</v-col>
															<v-col
																cols="12"
																sm="7"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.numero
																		: ""
																}}
															</v-col>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="text-left pa-2">
													<v-col cols="12" md="6" sm="6" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" sm="5"> Piso:</v-col>
															<v-col
																cols="12"
																sm="7"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.piso
																		: ""
																}}</v-col
															>
														</v-row>
													</v-col>
													<v-col cols="12" md="6" sm="6" class="pa-0 pr-2">
														<v-row>
															<v-col cols="12" sm="5"> Depto:</v-col>
															<v-col
																cols="12"
																sm="7"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.depto
																		: ""
																}}
															</v-col>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="text-left pa-2">
													<v-col cols="12" md="6" sm="6" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" md="5" sm="5"> Provincia:</v-col>
															<v-col
																cols="12"
																sm="7"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.provincia.name
																		: ""
																}}</v-col
															>
														</v-row>
													</v-col>
													<v-col cols="12" md="6" sm="6" class="pa-0 pr-2">
														<v-row>
															<v-col cols="12" sm="5"> Localidad:</v-col>
															<v-col
																cols="12"
																sm="7"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.localidad
																		: ""
																}}
															</v-col>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="text-left pa-2">
													<v-col cols="12" md="6" sm="6" class="pa-0 pr-4">
														<v-row>
															<v-col cols="12" sm="5"> Barrio:</v-col>
															<v-col
																cols="12"
																sm="7"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.barrio.name
																		: ""
																}}</v-col
															>
														</v-row>
													</v-col>
													<v-col cols="12" md="6" sm="6" class="pa-0 pr-2">
														<v-row>
															<v-col cols="12" sm="5"> Código Postal:</v-col>
															<v-col
																cols="12"
																sm="7"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.cp
																		: ""
																}}
															</v-col>
														</v-row>
													</v-col>
												</v-row>
												<v-row class="text-left pa-2">
													<v-col cols="12" md="12" sm="12" class="pa-0 pr-2">
														<v-row>
															<v-col cols="12" sm="6">
																Partido/Municipio/Depto:
															</v-col>
															<v-col
																cols="12"
																sm="6"
																class="text-left font-weight-bold font-italic border-bottom-cell"
															>
																{{
																	datosSeguro.guardaHabitual
																		? datosSeguro.guardaHabitual.partido
																		: ""
																}}</v-col
															>
														</v-row>
													</v-col>
												</v-row>
											</v-card-text>
										</v-card>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1">
									<v-col cols="12" md="6" sm="6" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="5">
												Dispone de Formulario GP-02:
											</v-col>
											<v-col
												cols="12"
												md="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosSeguro.con_formulario_gp02 ? "SI" : "NO"
												}}</v-col
											>
										</v-row>
									</v-col>
									<v-col cols="12" md="6" sm="6" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Formulario GP-02:</v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosSeguro.con_formulario_gp02
														? datosSeguro.formulario_gp02
														: ""
												}}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-card-text>
						</base-material-card>
					</v-col>
				</v-row>

				<v-row class="mt-2">
					<v-col cols="12" md="12" sm="12">
						<base-material-card color="primary" class="px-5 py-3">
							<template v-slot:heading>
								<div class="text-h5 font-weight-light my-2">
									Datos del Registro Seccional
								</div>
							</template>
							<v-card-text>
								<v-row class="text-left pa-1 pt-3">
									<v-col cols="12" md="4" sm="4" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="6"> Provincia: </v-col>
											<v-col
												cols="12"
												sm="6"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosRegistro.provincia
														? datosRegistro.provincia.name
														: ""
												}}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" md="4" sm="4" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Departamento:</v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosRegistro.depto ? datosRegistro.depto : "" }}
											</v-col>
										</v-row>
									</v-col>
									<v-col cols="12" md="4" sm="4" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Localidad: </v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosRegistro.localidad ? datosRegistro.localidad : ""
												}}</v-col
											>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1 pt-3">
									<v-col cols="12" md="8" sm="8" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="3"> Calle: </v-col>
											<v-col
												cols="12"
												sm="9"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosRegistro.calle ? datosRegistro.calle : ""
												}}</v-col
											>
										</v-row>
									</v-col>

									<v-col cols="12" md="4" sm="4" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="5"> Altura Exacta:</v-col>
											<v-col
												cols="12"
												sm="7"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{ datosRegistro.numero ? datosRegistro.numero : "" }}
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1 pt-3">
									<v-col cols="12" md="12" sm="12" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="2"> Observaciones: </v-col>
											<v-col
												cols="12"
												sm="10"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosRegistro.observaciones
														? datosRegistro.observaciones
														: ""
												}}</v-col
											>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="text-left pa-1 pt-3">
									<v-col cols="12" md="12" sm="12" class="pa-0 pr-4">
										<v-row>
											<v-col cols="12" sm="2"> Registro: </v-col>
											<v-col
												cols="12"
												sm="10"
												class="text-left font-weight-bold font-italic border-bottom-cell"
											>
												{{
													datosRegistro.registro ? datosRegistro.registro : ""
												}}</v-col
											>
										</v-row>
									</v-col>
								</v-row>
							</v-card-text>
						</base-material-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col class="text-right" cols="12" md="12" sm="12">
						<v-btn color="primary-2" @click="back()">Atras</v-btn>
						<v-btn color="primary" @click="next()">Finalizar</v-btn>
					</v-col>
				</v-row>
				<br />
			</v-card-text>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<style scoped>
.border-bottom-cell {
	border-bottom: 2px dotted #c2c2c2;
	background: #eee;
	margin-top: 5px;
	padding-top: 5px;
	padding-bottom: 0px;
	margin-bottom: 10px;
	border-radius: 5px;
}
.right-input >>> input {
	text-align: right;
}
.chip-indicadores {
	color: "blue-grey--text darken-4";
	font-size: 14px;
	/*font-family: 'Material Icons';*/
	/*font-weight: bold;*/
	font-style: normal;
}

.primary-change {
	color: #fff !important;
	/*background-color: #a7a8a8 !important;*/
	background-color: #105cfa !important;
	font-size: 20px;
	font-weight: bold;
	height: 70px !important;
	display: flex;
	align-items: center;
}

.col-f12 {
	text-align: center;
	font-size: 2rem;
	/*border-radius: 28px;*/
	font-style: #a0a0a0 !important;
	color: #a0a0a0 !important;
	font-weight: 500 !important;
	/*border-radius: 28px;*/
	background: #eee;
	padding: 8px 0px 23px 17px;
	height: 55px;
	margin-top: 7px;
	margin-left: 7px !important;
	margin-right: 15px;
}
. >>> input {
	text-align: center;
	font-size: 1.5rem;
	color: #105cfa !important;
	margin-top: 16px;
}
</style>
<script>
	export default {
		name: "DatosVp",
		components: {},
		props: {
			formData: { Object, default: null },
		},
		data: () => ({
			snackbar: false,
			color: "success",
			timeout: 4000,
			text: "Se ha registrado una nueva solicitud",
			search: "",
			routeTipoCertificado: "tipo_certificados",
			routeTipoTramite: "tipo_tramites",
			routeTipoVehiculo: "tipo_vehiculos",
			routeAseguradora: "aseguradoras",
			routeCobertura: "tipo_cobertura",
			tipoCertificado: "",
			tipoTramite: "",
			tipoVehiculo: "",
			aseguradora: "",
			cobertura: "",
			tipoAdquisicion: "",
			modoAdquisicion: "",
			moneda: "",
			tipoFactura: "",
			datosVehiculo: {},
			datosAdquisicion: {},
			datosSeguro: {},
			datosRegistro: {},
			datosTitulares: [],
			expTitulares: [],
			keyDatosTitulares: 0,
			panel: [0],
			panelDomLegal: [],
			panelDomReal: [],
			tipos_coberturas: [
				{ id: "1", nombre: "Todo Riesgo" },
				{ id: "2", nombre: "Todo Riesgo Franquicia 5%" },
				{ id: "3", nombre: "Tercero completo" },
				{ id: "4", nombre: "Resposabilidad Civil" },
			],
		}),

		methods: {
			async getTipoCertificado(id) {
				if (id) {
					await this.$axiosApi
						.getById(this.routeTipoCertificado, id)
						.then((r) => {
							console.log(r);
							this.tipoCertificado = r.data.data.nombre;
						})
						.catch(function (error) {
							this.$respuesta(vm, error, 3, true);
						});
				}
			},
			async getTipoTramite(id) {
				if (id) {
					await this.$axiosApi
						.getById(this.routeTipoTramite, id)
						.then((r) => {
							this.tipoTramite = r.data.data.nombre;
						})
						.catch(function (error) {
							this.$respuesta(vm, error, 3, true);
						});
				}
			},
			async getTipoVehiculo(id) {
				if (id) {
					await this.$axiosApi
						.getById(this.routeTipoVehiculo, id)
						.then((r) => {
							this.tipoVehiculo = r.data.data.nombre;
						})
						.catch(function (error) {
							this.$respuesta(vm, error, 3, true);
						});
				}
			},
			async getAseguradora(id) {
				if (id) {
					await this.$axiosApi
						.getById(this.routeAseguradora, id)
						.then((r) => {
							this.aseguradora = r.data.data.nombre;
						})
						.catch(function (error) {
							this.$respuesta(vm, error, 3, true);
						});
				}
			},
			async getTipoCobertura(id) {
				if (id) {
					//console.log("tipos coberturas");
					let filter = this.tipos_coberturas.filter((x) => x.id == id);
					//console.log(filter);
					this.cobertura = filter.length > 0 ? filter[0].nombre : "";
					//console.log(this.cobertura);
					/*
																																																																																																																																																																															      await this.$axiosApi
																																																																																																																																																																															        .getById(this.routeCobertura, id)
																																																																																																																																																																															        .then((r) => {
																																																																																																																																																																															          this.cobertura = r.data.data.nombre;
																																																																																																																																																																															        })
																																																																																																																																																																															        .catch(function (error) {
																																																																																																																																																																															          this.$respuesta(vm, error, 3, true);
																																																																																																																																																																															        });
																																																																																																																																																																															      */
				}
			},
			back() {
				this.$emit("send-message", "6");
			},
			next() {
				//if (this.$refs.form.validate()) {
				this.$emit("send-message", "8", "7", this.form);
				/*} else {
																																																																																																																																																																															        this.snackbar = true;
																																																																																																																																																																															        this.text = "Verifique los datos ingresados y/o campos obligatorios";
																																																																																																																																																																															        this.color = "error";
																																																																																																																																																																															      }*/
			},
		},
		computed: {},
		created() {
			//console.log(this.formData);
			/*if (this.formData.tipo_certificado_id) {
																																																																																																																																																																															      this.getTipoCertificado(this.formData.tipo_certificado_id);
																																																																																																																																																																															    }
																																																																																																																																																																															    if (this.formData.tipo_tramite_id) {
																																																																																																																																																																															      this.getTipoTramite(this.formData.tipo_tramite_id);
																																																																																																																																																																															    }
																																																																																																																																																																															    if (this.formData.tipo_vehiculo_id) {
																																																																																																																																																																															      this.getTipoVehiculo(this.formData.tipo_vehiculo_id);
																																																																																																																																																																															    }*/
		},

		watch: {},
		mounted() {
			//console.log("mounted vp ");
			//console.log(this.formData);
			if (this.formData) {
				this.getTipoCertificado(this.formData.tramite.tipo_certificado_id);
				this.getTipoTramite(this.formData.tramite.tipo_tramite_id);
				this.getTipoVehiculo(this.formData.tramite.tipo_vehiculo_id);
				this.getAseguradora(this.formData.seguro.aseguradora_id);
				this.getTipoCobertura(this.formData.seguro.tipo_cobertura_id);
				this.datosVehiculo = this.formData.vehiculo ? this.formData.vehiculo : {};
				this.datosAdquisicion = this.formData.adquisicion
					? this.formData.adquisicion
					: {};
				this.datosSeguro = this.formData.seguro ? this.formData.seguro : {};
				this.datosRegistro = this.formData.registro ? this.formData.registro : {};
				if (this.formData.titulares) {
					this.datosTitulares = this.formData.titulares.titulares;
					this.keyDatosTitulares = this.keyDatosTitulares + 1;
				}
			}

			//this.getTipoCertificado(this.formData.tipo_certificado_id);

			if (
				this.$root.$children[0].$children[0].$children[0].$children[0].$vnode
					.componentOptions.tag == "dashboard-core-app-bar"
			)
				this.$root.$children[0].$children[0].$children[0].$children[0].setDrawer(
					false
				);
			if (
				this.$root.$children[0].$children[0].$children[0].$children[1].$vnode
					.componentOptions.tag == "dashboard-core-app-bar"
			)
				this.$root.$children[0].$children[0].$children[0].$children[1].setDrawer(
					false
				);

			console.log("Componente Datos Vp creado");
		},
	};
</script>
